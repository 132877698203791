import Home from 'components/svg/icons/Home';
import ActionItems from 'components/svg/icons/ActionItems';
import AgentInfo from 'components/svg/icons/AgentInfo';
import MyAppointments from 'components/svg/icons/MyAppointments';
import CarrierResources from 'components/svg/icons/CarrierResources';
import Commissions from 'components/svg/icons/Commissions';
import Files from 'components/svg/icons/Files';
import Marketing from 'components/svg/icons/Marketing';
import Videos from 'components/svg/icons/Videos';
import OMProducts from 'components/svg/icons/OMProducts';
import Learning from 'components/svg/icons/Learning';
import Info from 'components/svg/icons/Info';
import LogOut from 'components/svg/icons/LogOut';
import Plus from 'components/svg/icons/Plus';
import Web from 'components/svg/icons/Web';
import CommissionRates from 'components/svg/icons/CommissionRates';
import Bonus from 'components/svg/icons/Bonus';
import Lead from 'components/svg/icons/Lead';
import {
  BusinessOutlined,
  FileDownloadOutlined,
  Key,
  PeopleAltOutlined,
  QuestionMark
} from '@mui/icons-material';

const icons = {
  home: Home,
  actionItems: ActionItems,
  agentInfo: AgentInfo,
  myAppointments: MyAppointments,
  carrierResources: CarrierResources,
  commissions: Commissions,
  files: Files,
  marketing: Marketing,
  videos: Videos,
  oMProducts: OMProducts,
  learning: Learning,
  info: Info,
  logOut: LogOut,
  plus: Plus,
  web: Web,
  commissionRates: CommissionRates,
  bonus: Bonus,
  lead: Lead,
  people: PeopleAltOutlined,
  agency: BusinessOutlined,
  download: FileDownloadOutlined,
  resetPassword: Key,
  question: QuestionMark
};

export default icons;
