import Input from 'components/Input/Input';
import React from 'react';
import FormWithEditButton from '../FormWithEditButton/FormWithEditButton';
import style from './bankAccountForm.module.scss';
import propTypes from 'prop-types';
import bankAccountFactory from 'helpers/factory/bankAccountFactory';

function BankAccountForm({ user = {}, onClick }) {
  let bankName = user.Name_of_Financial_Institution
    ? user.Name_of_Financial_Institution
    : '-';
  let accountNumber = user.Account_Number
    ? bankAccountFactory(user.Account_Number)
    : '-';

  return (
    <FormWithEditButton className={style.withButton} onClick={onClick}>
      <form className={style.bankAccountForm}>
        <Input
          disabled
          label='Bank Account:'
          value={`${bankName} ${accountNumber}`}
          placeholder='-'
          lightLabel
        />
      </form>
    </FormWithEditButton>
  );
}

BankAccountForm.propTypes = {
  user: propTypes.object,
  onClick: propTypes.func
};

export default BankAccountForm;
