import Input from 'components/Input/Input';
import InputWithCopyButton from 'components/InputWithCopyButton/InputWithCopyButton';
import React from 'react';
import FormWithEditButton from '../FormWithEditButton/FormWithEditButton';
import style from './residentForm.module.scss';
import propTypes from 'prop-types';

function ResidentForm({ user = {}, onClick }) {
  return (
    <FormWithEditButton className={style.residentForm} onClick={onClick}>
      <>
        <Input
          label='State:'
          value={
            user.Resident_Insurance_License_State
              ? user.Resident_Insurance_License_State
              : ''
          }
          placeholder='-'
          lightLabel
          disabled
        />
        <InputWithCopyButton
          label='Licence Number:'
          value={
            user.License_Resident_Insurance_Number
              ? user.License_Resident_Insurance_Number
              : ''
          }
          placeholder='-'
          lightLabel
          disabled
          // className={style.withCopy}
        />
        <Input
          label='Expiration date:'
          value={
            user.Resident_Insurance_License_Expiration_Date
              ? user.Resident_Insurance_License_Expiration_Date
              : ''
          }
          placeholder='-'
          lightLabel
          disabled
        />
      </>
    </FormWithEditButton>
  );
}

ResidentForm.propTypes = {
  user: propTypes.object,
  onClick: propTypes.func
};

export default ResidentForm;
