import React, { useRef } from 'react';
import Input from 'components/Input/Input';
import style from './inputWithCopyButton.module.scss';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { showAlert } from 'state/actions/site';
import { ContentCopy } from '@mui/icons-material';
import Button from 'components/Button/Button';

function InputWithCopyButton({ className, ...inputProps }) {
  const input = useRef(null);
  const dispatch = useDispatch();

  const copyText = () => {
    navigator.clipboard.writeText(input.current.value);
    dispatch(showAlert({ message: 'Copied to clipboard!', code: 'success' }));
  };

  return (
    <span className={`${style.inputWithCopyButton} ${className}`}>
      <Input ref={input} {...inputProps} />
      <Button
        size='xsmall'
        type='secondary'
        text={'Copy'}
        icon={<ContentCopy fontSize='inherit' />}
        onClick={copyText}
      />
    </span>
  );
}

InputWithCopyButton.propTypes = {
  className: propTypes.string
};

export default InputWithCopyButton;
