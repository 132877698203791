import React from 'react';
import PropTypes from 'prop-types';
import style from './ActiveAppointments.module.scss';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import getCompanyVariables from 'helpers/getCompanyVariables';

const CarrierList = ({ carriers, loading }) => {
  const company = getCompanyVariables();
  const navigate = useNavigate();
  const isLongText = (text) => {
    return text.length > 20;
  };

  return (
    <Card
      withHr={true}
      title='Active appointments'
      fill
      type='default'
      loading={loading}
    >
      <div>
        {company === 'oneill' ? (
          <>
            <div className={style.cardContent}>
              <div className={style.spansContainer}>
                {carriers.map((RA, index) => (
                  <span
                    className={`${RA.status === 'Pending' ? style.labelsPending : RA.status === 'Confirmed' ? style.labelsConfirmed : style.defaultStyle} ${isLongText(RA.carrierName) ? style.longText : ''}`}
                    key={index}
                  >
                    {RA.carrierName}
                  </span>
                ))}
              </div>
            </div>
            <div className={style.buttonContainer}>
              <Button
                size='medium'
                text='View appointments'
                type='primaryDarkened'
                onClick={() => navigate('/myAppointments')}
              />
            </div>
          </>
        ) : (
          <span>Coming Soon! Within the next few weeks, you will be able to view your current appointments and request new appointments.</span>
        )}
      </div>
    </Card>
  );
};

CarrierList.propTypes = {
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      carrierName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    })
  ).isRequired,
  loading: PropTypes.bool,
};

export default CarrierList;
